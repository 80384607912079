<template>
  <!-- NAME[epic=销售] 销售排行榜 -->
  <div class="orderTest-container">
    <el-radio-group v-model="tabPosition" style="margin-bottom: 20px">
      <el-radio-button label="1">商品销售排名</el-radio-button>
      <el-radio-button label="2">业务员销售排名</el-radio-button>
      <el-radio-button label="3">终端销售排名</el-radio-button>
    </el-radio-group>
    <div v-show="tabPosition == 1">
      <!-- 商品销售排名 -->
      <goods ref="goods"></goods>
    </div>
    <div v-show="tabPosition == 2">
      <!-- 业务员销售排名 -->
      <staff ref="staff"></staff>
    </div>
    <div v-show="tabPosition == 3">
      <!-- 终端销售排名 -->
      <client ref="client"></client>
    </div>
  </div>
</template>
<script>
  import Goods from './components/goods'
  import Staff from './components/staff'
  import Client from './components/client'
  export default {
    name: 'Ranking',
    components: { Goods, Staff, Client },
    data() {
      return {
        tabPosition: '1',
      }
    },
    computed: {},
    watch: {},
    created() {},
    mounted() {},
    methods: {},
  }
</script>
<style lang="scss" scoped></style>
