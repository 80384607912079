<template>
  <div class="orderTest-container">
    <el-form
      ref="form"
      :model="form"
      inline
      label-position="right"
      label-width="80px"
    >
      <el-form-item label="查询时段:" prop="">
        <el-date-picker
          v-model="time"
          type="daterange"
          range-separator="-"
          value-format="yyyy-MM-dd"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          style="width: 240px"
        ></el-date-picker>
      </el-form-item>
      <el-form-item label="" prop="area_id">
        <sale-area-select-tree
          ref="saleAreaSelectTree"
          @set-sale-select-id="selectArea"
        ></sale-area-select-tree>
      </el-form-item>
      <el-form-item label="" prop="channel_id">
        <el-select
          v-model="form.channel_id"
          clearable
          style="width: 140px"
          placeholder="渠道"
        >
          <el-option
            v-for="(i, idx) in channels"
            :key="idx"
            :value="i.id"
            :label="i.name"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="" prop="cust_level">
        <el-select
          v-model="form.cust_level"
          clearable
          style="width: 140px"
          placeholder="客户等级"
        >
          <el-option
            v-for="(i, idx) in clientLevelSelect"
            :key="idx"
            :value="i.id"
            :label="i.name"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="" prop="">
        <client-search
          :popper-class="'selectDC'"
          @select-id="clientSelect"
        ></client-search>
      </el-form-item>
      <el-form-item label="" prop="">
        <el-button
          type="primary"
          @click="
            () => {
              form.pageSize = 10
              form.pageNo = 1
              fetchData()
            }
          "
        >
          查询
        </el-button>
      </el-form-item>
      <el-form-item label="" prop="">
        <el-button type="warning" @click="resetForm">重置</el-button>
      </el-form-item>
      <el-form-item label="" prop="">
        <el-button @click="handleExport">导出</el-button>
      </el-form-item>
    </el-form>
    <el-table
      v-loading="loading"
      stripe
      :data="list"
      show-summary
      :summary-method="summaryFunction"
    >
      <!-- 序号 -->
      <el-table-column align="center" type="index" width="50">
        <template slot="header">
          <el-popover popper-class="custom-table-checkbox" trigger="hover">
            <el-checkbox-group v-model="checkList">
              <el-checkbox
                v-for="(item, index) in columns"
                :key="index"
                :label="item.label"
              ></el-checkbox>
            </el-checkbox-group>
            <el-button slot="reference" type="text">
              <vab-remix-icon icon="settings-line" />
            </el-button>
          </el-popover>
        </template>
      </el-table-column>
      <el-table-column
        v-for="(item, tableIndex) in finallyColumns"
        :key="tableIndex"
        :prop="item.prop"
        :label="item.label"
        width=""
        align="center"
      ></el-table-column>
    </el-table>
    <el-pagination
      :current-page="form.pageNo"
      :layout="layout"
      :page-size="form.pageSize"
      :total="total"
      background
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
    ></el-pagination>
  </div>
</template>
<script>
  import _ from 'lodash'
  import SaleAreaSelectTree from '@/baseComponents/saleAreaSelectTree'
  import ClientSearch from '@/baseComponents/clientSearch'
  import { clientCustChannel } from '@/api/ClientDetail'
  import { getClientLevelList } from '@/api/setPrice'
  import { custList } from '@/api/saleData'
  import { day_30 } from '@/utils/Time'
  import { downloadFile } from '@/api/Employee'

  export default {
    name: 'Client',
    components: { SaleAreaSelectTree, ClientSearch },
    data() {
      return {
        time: [],
        loading: false,
        channels: [],
        layout: 'total, sizes, prev, pager, next, jumper',
        total: 0,
        clientLevelSelect: [],
        list: [],
        checkList: [
          '客户名称',
          '销售区域',
          '渠道',
          '等级',
          '销售金额',
          '退货金额',
        ],
        columns: [
          {
            order: 1,
            label: '客户名称',
            prop: 'cust_name',
            width: '',
          },
          {
            order: 2,
            label: '销售区域',
            prop: 'area_name',
            width: '',
          },
          {
            order: 3,
            label: '渠道',
            prop: 'channel_name',
            width: '',
          },
          {
            order: 4,
            label: '等级',
            prop: 'class_name',
            width: '',
          },
          {
            order: 5,
            label: '销售金额',
            prop: 'sale_money',
            width: '',
          },
          {
            order: 6,
            label: '退货金额',
            prop: 'back_money',
            width: '',
          },
        ],
        form: {
          pageNo: 1, //页数,默认第一页
          pageSize: 10, //条数,默认10条
          sort: '', //排序方式 sale_money 销售金额,back_money退款金额,rate 毛利
          order: '', //排序大小,desc降序asc升序
          cust_id: '', //客户id
          cust_level: '',
          channel_id: '', //渠道id
          area_id: '', //区域id
          start_time: '', //开始时间
          end_time: '', //结束时间
        },
      }
    },
    computed: {
      finallyColumns() {
        let finallyArray = []
        this.checkList.forEach((checkItem) => {
          finallyArray.push(
            this.columns.filter((item) => item.label === checkItem)[0]
          )
        })
        return _.sortBy(finallyArray, (item) => item.order)
      },
    },
    watch: {
      time(val) {
        if (val) {
          this.form.start_time = val[0]
          this.form.end_time = val[1]
        } else {
          this.form.start_time = ''
          this.form.end_time = ''
        }
      },
    },
    created() {
      this.initTime()

      this.fetchData()
      // 渠道下拉
      clientCustChannel().then((d) => {
        this.channels = d.data
      })
      // 客户等级下拉
      getClientLevelList().then((res) => {
        this.clientLevelSelect = res.data
      })
    },
    mounted() {},
    activated() {
      this.fetchData()
    },
    methods: {
      async fetchData() {
        this.loading = true
        let { data, msg, totalCount } = await custList(this.form)
        this.total = totalCount
        this.list = data.list
        this.loading = false
      },
      selectArea(val) {
        this.form.area_id = val
      },
      clientSelect(val) {
        this.form.cust_id = val
      },
      handleCurrentChange(val) {
        this.form.pageNo = val
        this.fetchData()
      },
      handleSizeChange(val) {
        this.form.pageSize = val
        this.fetchData()
      },
      resetForm() {
        this.time = []
        this.$refs.form.resetFields()
        this.$refs.saleAreaSelectTree.resetForm()
      },
      initTime() {
        this.time = day_30()
        this.form.start_time = this.time[0]
        this.form.end_time = this.time[1]
      },
      // 表格合计fun
      summaryFunction(param) {
        const { columns, data } = param
        // 需要合计的下标
        let i = [5, 6]
        let sums = []
        // let val = data.map((item) => {
        //   console.log(item)
        // })
        columns.forEach((col, idx) => {
          i.forEach((n) => {
            if (idx == n) {
              console.log(col.property)
              const val = data.map((item) => Number(item[col.property]))
              console.log(val)
              if (!val.every((value) => isNaN(value))) {
                const a = val.reduce((total, num) => {
                  if (!isNaN(total)) {
                    return total + num
                  }
                })
                sums[n] = a + ' 元'
              }
            }
          })
          if (idx == 0) {
            sums[idx] = '合计'
          }
        })
        console.log('sss', sums)
        return sums
      },
      handleExport() {
        downloadFile(
          '/saleAdmin/rank/customer-top-export',
          '客户排行榜.xlsx',
          this.form
        )
      },
    },
  }
</script>
<style lang="scss" scoped></style>
