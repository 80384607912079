<template>
  <div class="orderTest-container">
    <el-form
      ref="form"
      :model="form"
      inline
      label-position="right"
      label-width="80px"
    >
      <el-form-item label="查询时段:" prop="">
        <el-date-picker
          v-model="time"
          type="daterange"
          range-separator="-"
          value-format="yyyy-MM-dd"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          style="width: 240px"
        ></el-date-picker>
      </el-form-item>
      <el-form-item label="" prop="">
        <brand-select
          ref="brandSelect"
          :duoxuan="true"
          style="width: 190px"
          @brand-select-change="brandSelect"
        ></brand-select>
      </el-form-item>
      <el-form-item label="" prop="">
        <el-select
          v-model="form.goods_type"
          clearable
          style="width: 140px"
          placeholder="销售类型"
        >
          <el-option
            v-for="(i, idx) in saleSelect"
            :key="idx"
            :value="i.id"
            :label="i.name"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="" prop="">
        <goods-search
          ref="goodsSearch"
          style="width: 190px"
          :is-table="false"
          @select-goods-all="selectGoods"
        ></goods-search>
      </el-form-item>
      <el-form-item label="" prop="">
        <el-button
          type="primary"
          @click="
            () => {
              form.pageSize = 10
              form.pageNo = 1
              fetchData()
            }
          "
        >
          查询
        </el-button>
      </el-form-item>
      <el-form-item label="" prop="">
        <el-button type="warning" @click="resetForm">重置</el-button>
      </el-form-item>
      <el-form-item label="" prop="">
        <el-button @click="handleExport">导出</el-button>
      </el-form-item>
    </el-form>
    <el-table
      v-loading="loading"
      stripe
      :data="list"
      show-summary
      :summary-method="summaryFunction"
    >
      <!-- 序号 -->
      <el-table-column align="center" type="index" width="50">
        <template slot="header">排名</template>
      </el-table-column>
      <el-table-column
        prop="goods_name"
        label="商品名称"
        width=""
        align="center"
      ></el-table-column>
      <el-table-column
        prop="specs"
        label="规格"
        width=""
        align="center"
      ></el-table-column>
      <el-table-column
        prop="quantity"
        label="销售数量"
        width=""
        align="center"
      ></el-table-column>
      <el-table-column
        prop="goods_money"
        label="销售金额"
        width=""
        align="center"
      ></el-table-column>
    </el-table>
    <el-pagination
      :current-page="form.pageNo"
      :layout="layout"
      :page-size="form.pageSize"
      :total="total"
      background
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
    ></el-pagination>
  </div>
</template>
<script>
  import GoodsSearch from '@/baseComponents/goodsSearch'
  import BrandSelect from '@/baseComponents/brandSelect'
  import { goodsList, saleTypeList } from '@/api/saleData'
  import { day_30 } from '@/utils/Time'
  import { downloadFile } from '@/api/Employee'

  export default {
    name: 'Goods',
    components: { GoodsSearch, BrandSelect },
    data() {
      return {
        time: [],
        loading: false,
        saleSelect: [],
        list: [],
        layout: 'total, sizes, prev, pager, next, jumper',
        total: 0,
        form: {
          pageNo: 1, //页数,默认第一页
          pageSize: 10, //条数,默认10条
          goods_type: '', //销售类型
          brand_id: '', //品牌id
          goods_id: '', //商品id
          start_time: '', //开始时间
          end_time: '', //结束时间
          order: '', //排序大小,desc降序asc升序
          sort: '', //排序方式quantity数量,goods_money金额
        },
      }
    },
    computed: {},
    watch: {
      time(val) {
        if (val) {
          this.form.start_time = val[0]
          this.form.end_time = val[1]
        } else {
          this.form.start_time = ''
          this.form.end_time = ''
        }
      },
    },
    created() {
      this.initTime()
      this.fetchData()
      saleTypeList().then((res) => {
        this.saleSelect = res.data
      })
    },
    mounted() {},
    activated() {
      this.fetchData()
    },
    methods: {
      async fetchData() {
        this.loading = true
        let { data, code, msg, totalCount } = await goodsList(this.form)
        console.log(data)
        this.list = data.list
        this.total = totalCount
        this.loading = false
      },
      initTime() {
        this.time = day_30()
        this.form.start_time = this.time[0]
        this.form.end_time = this.time[1]
      },
      brandSelect(val) {
        console.log('多选事件2', val)
        this.form.brand_id = val
      },
      selectGoods(val) {
        console.log(val)
        this.form.goods_id = val.goods_id
      },
      handleCurrentChange(val) {
        this.form.pageNo = val
        this.fetchData()
      },
      handleSizeChange(val) {
        this.form.pageSize = val
        this.fetchData()
      },
      resetForm() {
        this.time = []
        this.$refs.brandSelect.resetForm()
        this.$refs.goodsSearch.resetForm()
        this.form.goods_type = ''
        this.form.goods_id = ''
      },
      // 表格合计fun
      summaryFunction(param) {
        const { columns, data } = param
        // 需要合计的下标
        let i = [4]
        let sums = []
        // let val = data.map((item) => {
        //   console.log(item)
        // })
        columns.forEach((col, idx) => {
          i.forEach((n) => {
            if (idx == n) {
              console.log(col.property)
              const val = data.map((item) => Number(item[col.property]))
              console.log(val)
              if (!val.every((value) => isNaN(value))) {
                const a = val.reduce((total, num) => {
                  if (!isNaN(total)) {
                    return total + num
                  }
                })
                sums[n] = a.toFixed(2) + ' 元'
              }
            }
          })
          if (idx == 0) {
            sums[idx] = '合计'
          }
        })
        console.log('sss', sums)
        return sums
      },
      handleExport() {
        downloadFile(
          '/saleAdmin/rank/goods-top-export',
          '商品排行榜.xlsx',
          this.form
        )
      },
    },
  }
</script>
<style lang="scss" scoped></style>
